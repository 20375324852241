import {Component, Input, OnInit} from '@angular/core';
import {b} from "@fullcalendar/core/internal-common";
import {Router} from "@angular/router";
import {PopoverController} from "@ionic/angular";
import {FacebookService, ToastService} from "../../../core/services";
import {CitycalendarServices} from "../../../features/city-calendar/services/citycalendar.services";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-options-popover',
  templateUrl: './options-popover.component.html',
  styleUrls: ['./options-popover.component.scss'],
})
export class OptionsPopoverComponent implements OnInit {
  @Input() isSocialMedia: boolean = false
  @Input() isBookletOverview: boolean = false
  @Input() showJatak: boolean;
  @Input() showBooklet: boolean;
  @Input() showSms: boolean;
  @Input() showRegularPost: boolean
  @Input() showRedmad: boolean = false
  @Input() showAuction: boolean = false
  @Input() showCompetition: boolean = false
  @Input() showSpinning: boolean = false
  @Input() showCard: boolean = false
  @Input() showCity: boolean = false
  @Input() showMessengerClub: boolean = false

  constructor(
    private _router: Router,
    private popoverCtrl: PopoverController,
    private _fbService: FacebookService,
    private _service: CitycalendarServices,
    private _toastService: ToastService,
    private _translate: TranslateService
  ) {
  }

  ngOnInit() {
  }

  async onCreate(type: string) {
    if (this.isSocialMedia && type !== 'card') {
      if (!(await this._fbService.isConnectedToFb())) {
        return;
      }
    }
    this.dismissPopover().then()
    if (type === 'jatak') {
      this._router.navigate(['/tabs/posts/jatak'], {
        queryParams: {origin: 'dashboard', type: 'jatak'},
        replaceUrl: true
      }).then()
    } else if (type === 'sms') {
      this._router.navigate(['/tabs/sms/create'], {queryParams: {origin: 'dashboard'}, replaceUrl: true}).then()
    } else if (type === 'regular') {
      this._router.navigate(['/tabs/posts/regular/create'], {
        queryParams: {origin: 'dashboard', type: 'regular'},
        replaceUrl: true
      }).then()
    } else if (type === 'challenge') {
      this._router.navigate(['/tabs/jatak/jatak-plus'], {queryParams: {origin: 'dashboard'}, replaceUrl: true}).then()
    } else if (type === 'redmad') {
      this._router.navigate(['/tabs/redmad/create'], {queryParams: {origin: 'dashboard'}}).then()
    } else if (type === 'competition') {
      this._router.navigate(['/tabs/posts/competition/create'], {
        queryParams: {origin: 'dashboard', type: 'competition'},
        replaceUrl: true
      }).then()
    } else if (type === 'auction') {
      this._router.navigate(['/tabs/posts/auction/create'], {
        queryParams: {origin: 'dashboard', type: 'auction'},
        replaceUrl: true
      }).then()
    } else if (type === 'wheel') {
      this._router.navigate(['/tabs/posts/wheel/create'], {
        queryParams: {origin: 'dashboard', type: 'wheel'}, replaceUrl: true
      }).then()
    } else if (type === 'card') {
      this._router.navigate(['/tabs/posts/card/create'], {
        queryParams: {origin: 'dashboard', type: 'card'},
        replaceUrl: true
      }).then()
    } else if (type === 'gift') {
      this.handleGiftBoxCreate()
    } else if (type === 'messenger') {
      this._router.navigate(['/tabs/messenger/create'], {
        queryParams: {origin: 'dashboard', type: 'messenger'},
        replaceUrl: true
      }).then()
    } else {
      this._router.navigate(['/tabs/booklet/create'], {queryParams: {origin: 'dashboard'}, replaceUrl: true}).then()
    }
  }

  private handleGiftBoxCreate() {
    let message = this._translate.instant('_you_cannot_create_city_calendar_now_')
    this._service.checkCityPostStatus().subscribe({
      next: (res) => {
        if (res['activeGiftBox']) {
          this._toastService.toast(message, 'error').then()
        } else {
          this._router.navigate(['/tabs/posts/gift/create'], {
            queryParams: {origin: 'dashboard', type: 'gift'}, replaceUrl: true
          }).then()
        }
      }
    })
  }

  private async dismissPopover() {
    await this.popoverCtrl.dismiss();
  }
}


