import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {JatakService} from "../../../features/jatak/services";
import {Folder} from "../../interface";
import {ErrorHandlerService} from "../../../core/services";

@Component({
  selector: 'app-template-folder-list',
  templateUrl: './template-folder-list.component.html',
  styleUrls: ['./template-folder-list.component.scss'],
})
export class TemplateFolderListComponent implements OnInit {
  pageTitle: string = 'Templates'
  showTemplateFolder: boolean = false
  isTemplateFolder: boolean = true
  showBackArrow: boolean = true
  imageLoaded = false;
  templateFolders: Folder[] = [];
  placeholderImageUrl = './assets/images/image89.svg';
  isLoading: boolean = false
  page: number = 1;
  last_page: number = 0;
  isStore: boolean = false;
  isAssigned : boolean = false
  constructor(
    private router: Router,
    private _service: JatakService,
    private _errorHandler: ErrorHandlerService
  ) {
  }

  ngOnInit() {
    this.getFolderList()
  }

  getFolderList() {
    this.isLoading = true
    this._service.getFolderList(this.page).subscribe({
      next: (res) => {
        this.templateFolders = res['data']['folders'];
        this.isStore = res['data']['details']['own_template'];
        this.isAssigned = res['data']['details']['store'];
        this.last_page = res['data']['meta'].last_page;
        this.isLoading = false
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  onImageLoad($event: Event) {
    this.imageLoaded = true;
  }

  loadMore(event) {
    if (this.page === this.last_page) {
      event.target.complete()
      return;
    }
    this.page++
    this._service.getFolderList(this.page).subscribe({
      next: (res) => {
        this.templateFolders = this.templateFolders.concat(res['data']['folders']);
        this.isStore = res['data']['details']['own_template'];
        this.isAssigned = res['data']['details']['store'];
        this.last_page = res['data']['meta'].last_page;
        this.isLoading = false
        event.target.complete()
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  handleResultFolder(event : any) {
    this.templateFolders = event
  }

  onNavigateToTemplates(name: string, id?: number) {
    if (name === 'saved') {
      this.router.navigate([`/tabs/jatak/saved-templates`]).then()
    } else if (name === 'assigned') {
      this.router.navigate([`/tabs/jatak/assign-templates`]).then()
    } else {
      this.router.navigate([`/tabs/jatak/template-list/${id}`]).then()
    }
  }

  formatDescription(text:string) {
    return text.substring(0,10)
  }

  private handleError(err: any) {
    this._errorHandler.handleError(err, '')
  }

}
