import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {AngularPinturaModule} from '@pqina/angular-pintura';
import {FullCalendarModule} from '@fullcalendar/angular';
import {MbscModule} from '@mobiscroll/angular';
import {SwiperModule} from "swiper/angular";
import {
  HeaderComponent,
  ControlErrorComponent,
  ButtonComponent,
  ImageHandlerComponent,
  FacebookConnectModalComponent,
  ImageEditorComponent,
  ListSkeletonComponent,
  OfferPreviewModalComponent,
  LanguagePopoverComponent,
  LanguageTranslatorComponent,
  NoPageComponent,
  MainComponent,
  SearchComponent,
  TemplateFolderListComponent,
  TemplateListComponent,
  TemplateListSkeletonComponent,
  SearchTemplateSkeletonComponent,
  TemplateFolderListSkeletonComponent,
  OptionsPopoverComponent,
  OfferPreviewComponent
} from './components';
import {
  ControlErrorContainerDirective,
  ControlErrorDirective,
  CursorTrackerDirective,
  FormSubmitDirective,
  TooltipDirective,
  ClickOutsideDirective
} from './directives';
import {
  DateFormatPipe,
  DateToString,
  Excerpt,
  FormatNumber,
  NumberPad,
  RemoveLink,
  SimpleDateFormatPipe,
  FormatNumberPipe
} from './pipes';
import {DynamicTranslate} from './pipes/translate.pipe';
import {ItemListSkeletonComponent} from "./components/item-list-skeleton/item-list-skeleton.component";

@NgModule({
  declarations: [
    HeaderComponent,
    ControlErrorComponent,
    ControlErrorDirective,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    TooltipDirective,
    ClickOutsideDirective,
    CursorTrackerDirective,
    ButtonComponent,
    ImageHandlerComponent,
    DateFormatPipe,
    SimpleDateFormatPipe,
    DateToString,
    NumberPad,
    DynamicTranslate,
    Excerpt,
    FormatNumber,
    FacebookConnectModalComponent,
    ImageEditorComponent,
    ListSkeletonComponent,
    OfferPreviewModalComponent,
    LanguageTranslatorComponent,
    LanguagePopoverComponent,
    NoPageComponent,
    MainComponent,
    RemoveLink,
    FormatNumberPipe,
    SearchComponent,
    TemplateFolderListComponent,
    TemplateListComponent , TemplateListSkeletonComponent,  SearchTemplateSkeletonComponent,
    TemplateFolderListSkeletonComponent,
    OptionsPopoverComponent,
    OfferPreviewComponent,
    ItemListSkeletonComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FullCalendarModule,
    MbscModule,
    AngularPinturaModule,
    TranslateModule,
    SwiperModule,
  ],
  exports: [
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderComponent,
    ControlErrorComponent,
    ControlErrorDirective,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    TooltipDirective,
    ClickOutsideDirective,
    CursorTrackerDirective,
    ButtonComponent,
    FullCalendarModule,
    ImageHandlerComponent,
    MbscModule,
    DateFormatPipe,
    FormatNumberPipe,
    SimpleDateFormatPipe,
    DateToString,
    NumberPad,
    DynamicTranslate,
    Excerpt,
    FormatNumber,
    FacebookConnectModalComponent,
    ListSkeletonComponent,
    OfferPreviewModalComponent,
    TranslateModule,
    LanguageTranslatorComponent,
    LanguagePopoverComponent,
    NoPageComponent,
    MainComponent,
    RemoveLink,
    SwiperModule,
    SearchComponent,
    TemplateFolderListComponent,
    TemplateListComponent,
    TemplateListSkeletonComponent,
    SearchTemplateSkeletonComponent,
    TemplateFolderListSkeletonComponent,
    OptionsPopoverComponent,
    OfferPreviewComponent,
    ItemListSkeletonComponent
  ]
})
export class SharedModule {
}
