import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PagetitleService {
  private _pageTitle = new Subject<string>()
  $title = this._pageTitle.asObservable()


  setPageTitle(title: string) {
    this._pageTitle.next(title)
  }
}

@Injectable({
  providedIn: 'root'
})
export class SpinningWheelSetting {
  private _isSpinningWheelSetting = new Subject<boolean>()
  $setting = this._isSpinningWheelSetting.asObservable()


  setSettingState(state: boolean) {
    this._isSpinningWheelSetting.next(state)
  }
}


@Injectable({
  providedIn: 'root'
})
export class SpinningWheelSettingToggle {
  private _showSettingPage = new Subject<boolean>()
  $setting = this._showSettingPage.asObservable()


  toggleSettingPage(state: boolean) {
    this._showSettingPage.next(state)
  }
}


@Injectable({
  providedIn: 'root'
})
export class SpinningWheelPreviewToggle {
  private _showPreviewPage = new Subject<boolean>()
  $preview = this._showPreviewPage.asObservable()


  togglePreview(state: boolean) {
    this._showPreviewPage.next(state)
  }
}
