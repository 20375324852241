import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  setData(key: string, value: any): void {
    (window as any)[key] = value;
  }

  getData(key: string): any {
    return (window as any)[key];
  }
}
