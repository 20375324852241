import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest, HttpResponse
} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {AuthStateService} from './auth-state.service';
import {UserService} from './user.service';
import {TokenService} from "./token.service";
import {ToastService} from "./toast.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

  constructor(private router: Router,
              private userService: UserService,
              private _translate: TranslateService,
              private toastService: ToastService,) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handle(req, next);
  }

  private setRequestHeaders(request: HttpRequest<any>) {
    const headerObject = {};
    if (request.method === 'POST' && !(request.body instanceof FormData)) {
      if (request.url.includes('api/bags') || request.url.includes('api/epay-settings') || request.url.includes('api/auctions') || request.url.includes('api/competitions') || request.url.includes('api/templates/competition') || request.url.includes('api/spinning-wheels')) {
        headerObject['Content-type'] = 'application/json';
      } else {
        headerObject['Content-type'] = 'application/x-www-form-urlencoded';
      }
    }
    if (request.method === 'GET') {
      headerObject['Content-type'] = 'application/json';
    }
    if (request.url.indexOf(environment.apiUrl) !== -1 ||
      request.url.indexOf(environment.jatakUrl) !== -1) {
      // *** If request are made after authentication add Token and KardexId on respective headers *** //
      const isActive = AuthStateService.isTokenActive();
      //console.log(AuthStateService.authUser);//this should not be null check on api response
      if (isActive) {
        headerObject['Authorization'] = `Bearer ${AuthStateService.authUser.value.token}`;//check this value how
      }
    }
    const headers = new HttpHeaders(headerObject);
    return request.clone({headers});
  }

  private handle(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(this.setRequestHeaders(req))
      .pipe(
        map((event: HttpEvent<any>) => event),
        catchError((httpError: HttpErrorResponse) => {
          const isTokenInvalid = httpError.error.code === -83 || httpError.error.code === -84 || httpError.error.code === -85;
          if (httpError.status === 401) {

            if (httpError.error.code === 6001) {
              AuthStateService.onLogout()
              TokenService.clearStorage()
              this.toastService.toast('You do not have permission to access the quicksale app', 'error')
              this.router.navigate(['/login'], {replaceUrl: true}).then();
              return throwError(httpError.error);
            }

            // if (httpError.error.action === 'CHANGE_PASSWORD_REQUIRED') {
            //   let errormessage = this._translate.instant('_change_password_')
            //   this.toastService.toast(errormessage, 'error').then()
            //   this.router.navigate(['/login/reset-password']).then()
            //   return;
            // }
            AuthStateService.onLogout()
            TokenService.clearStorage()
            this.router.navigate(['/login']).then();
            return throwError(httpError.error);
          }
          if (!environment.production) {
            console.log(httpError);
          }
          return throwError(httpError.error);
        })
      );
  }
}
