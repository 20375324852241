import {Component, OnInit, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import '@ckeditor/ckeditor5-build-inline/build/translations/da';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {EditorConfig} from '@ckeditor/ckeditor5-core';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditorComponent),
      multi: true
    }
  ]
})
export class TextEditorComponent implements ControlValueAccessor , OnChanges{
  @Input() placeholder : string = ''
  public Editor = ClassicEditor;
  public editorConfig: EditorConfig = {
    toolbar: [
      'bold',
      'italic',
      'link',
      '|',
      'numberedList',
      'bulletedList',
      'outdent',
      'indent',
    ],
    language: 'da',
    licenseKey: 'GPL',
    placeholder : this.placeholder
  };


  editorCtn: any;
  disabled = false;
  value: string;

  ngOnChanges(changes: SimpleChanges) {
    if(changes['placeholder']) {
      this.placeholder = changes['placeholder'].currentValue
      this.editorConfig = {
        ...this.editorConfig,
        placeholder : this.placeholder
      }
    }
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };
//**Registers a callback function that is called when the control's value changes in the UI.
  //@params[fn] The callback function to register
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

//**Registers a callback function that is called by the forms API on initialization to update the form model on blur.
  //@params[fn] the callback function to register
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

//**Writes a new value to the element.
  //@params[value] the new value for the element
  writeValue(value: any): void {
    this.value = value ? value : '';
  }

//** Function that is called by the forms API when the control status changes to or from 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
  //@params[isDisabled] the disabled status to set on the element
  //set disabled with the value of isDisabled
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

//** fired when content of the editor is changed
  //@params[event] object containing the editor and the CKEditor 5 change:data event object
  inputEvents(event) {
    this.onChange(this.editorCtn.getData());
  }

//**set editor
  //@params[editor] event object
  //set editorCtn is set with the editor
  setEditor(editor) {
    this.editorCtn = editor;
    editor.editing.view.document.on(
      'enter',
      (evt, data) => {
        editor.execute('enter');
        //Cancel existing event
        data.preventDefault();
        evt.stop();
      }, {priority: 'high'});
  }

}
