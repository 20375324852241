import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-offer-preview',
  templateUrl: './offer-preview.component.html',
  styleUrls: ['./offer-preview.component.scss'],
})
export class OfferPreviewComponent implements OnInit , OnChanges{
  @Input() formData: any
  @Input() isCompetition : boolean = false
  @Input() imageArray = []
  userplaceholderImg = "assets/image/user-dummy.jpg"
  @Output() showPreview: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['imageArray']) {
      this.imageArray = changes['imageArray'].currentValue
      console.log(this.imageArray)
    }
  }

  ngOnInit() {
  }

  closePreview() {
    this.showPreview.emit(false)
  }


}
