import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {WheelsServices} from "../../../features/spinning-wheel/services/wheels.services";
import {Platform} from "@ionic/angular";

@Component({
  selector: 'app-spinning-wheel-preview',
  templateUrl: './spinning-wheel-preview.component.html',
  styleUrls: ['./spinning-wheel-preview.component.scss'],
})
export class SpinningWheelPreviewComponent implements OnInit, OnChanges {
  @Input() formData: any
  @Input() imgUrl: string
  @Output() closePreview = new EventEmitter<boolean>(false)
  prizeCount: number = 3; // Example default
  spinUuid: string = '1';
  hasSpun: boolean = false;
  isWin: boolean = false;
  // Example data
  prizeStructures = [];
  wheelRotation: number = 0;
  prizeTitle: string = ''
  @Input() spinIndex = 0
  backgroundColor = ''
  textColor  = ''
  sequence = []
  isIOS : boolean = false
  prizeImg:string = ''

  constructor(
    private _service: WheelsServices,
    private platform: Platform
  ) {
    this.isIOS = this.platform.is('ios');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formData']) {
      this.formData = changes['formData'].currentValue
      this.backgroundColor = this.formData.main_bg_color
      this.textColor = this.formData.main_text_color
      this.handlePrizeStructure()
    }
  }

  ngOnInit() {

  }

  handlePrizeStructure() {

    this.prizeStructures = this.formData.prizes

    // Sequence logic
    this.prizeCount = this.formData.prizes.length;

    this.sequence = []

    const lose = 'lose';

    const sequences = {
      1: [0, lose, 0, lose],
      2: [1, lose, 0, 1, lose, 0],
      3: [2, lose, 0, 1, 2, lose, 0, 1],
    };
    this.sequence = sequences[this.prizeCount]
    // Map sequence into prizeStructures with lose object
    const finalStructure = this.sequence.map(item =>
      item === "lose" ? "lose" : this.prizeStructures[item]
    );
    this.prizeStructures = finalStructure
  }

  handleClose() {
    this.closePreview.next(false)
  }

  onSpin(): void {
    if (this.hasSpun) return;
    this.prizeCount = this.formData.prizes.length;
    let index = this.spinIndex === -1 ? 'lose' : this.spinIndex - 1
    const winIndex = this.sequence.findIndex(elem => elem === index)
    this.formData.prizes.forEach((item, index) => {
      if (index === this.spinIndex - 1) {
        this.prizeTitle = item.winning_text
        this.prizeImg = item.imageUrl
      }
    })
    // Make API call to trigger spin logic
    this.wheelRotation = this.calculateRotation(this.prizeCount, winIndex);
    setTimeout(() => {
      this.hasSpun = true;
    }, 5000)
  }


  calculateRotation(prizeCount: number, prizePosition: number): number {
    let initialDegree = 720 * 4;

    switch (prizeCount) {
      case 1:
        return initialDegree + (prizePosition === 1 ? -45 : 45);
      case 2:
        switch (prizePosition) {
          case 0:
            return initialDegree + 90;
          case 1:
            return initialDegree + 30;
          case 2:
            return initialDegree - 30;
        }
        break;
      case 3:
        switch (prizePosition) {
          case 0:
            return initialDegree + 66.5;
          case 1:
            return initialDegree + 21.5;
          case 2:
            return initialDegree - 21.5;
          case 3:
            return initialDegree - 66.5;
        }
        break;
    }

    return initialDegree;
  }


  animateWheel(): void {
    const wheel = document.getElementById('wheel');
    const initialDegree = 720 * 4; // Initial spin degree

    let targetDegree =
      initialDegree +
      (this.isWin ? this.getWinningDegree() : this.getLosingDegree());

    if (wheel) {
      wheel.style.transition = 'transform 5s ease-out';
      wheel.style.transform = `rotate(${targetDegree}deg)`;

      wheel.addEventListener('transitionend', () => {
        if (this.isWin) {
          this.showConfetti();
          this.switchToWinScreen();
        } else {
          this.switchToLoseScreen();
        }
      });
    }
  }

  getWinningDegree(): number {
    switch (this.prizeCount) {
      case 1:
        return 45;
      case 2:
        return -30;
      case 3:
        return -21.5;
      default:
        return 0;
    }
  }

  getLosingDegree(): number {
    switch (this.prizeCount) {
      case 1:
        return -45;
      case 2:
        return 30;
      case 3:
        return 21.5;
      default:
        return 0;
    }
  }

  showConfetti(): void {
    // Logic for confetti
  }

  switchToWinScreen(): void {
    // Display winning screen logic
  }

  switchToLoseScreen(): void {
    // Display losing screen logic
  }

}
