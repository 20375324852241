import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  setData(key: string, value: any): void {
    (window as any)[key] = value;
  }

  getData(key: string): any {
    return (window as any)[key];
  }
}

@Injectable({
  providedIn: 'root'
})
export class HeaderState {
  private _isHeaderVisible = new Subject<boolean>()
  $header = this._isHeaderVisible.asObservable()


  setHeader(state: boolean) {
    this._isHeaderVisible.next(state)
  }
}
