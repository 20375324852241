// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-thumbnail {
  width: 40px;
  height: 40px;
  --border-radius: 8px;
}

.divider {
  border-top: 1px solid #C5C5C7;
  margin: 16px 0;
}

.list-text-container {
  margin: 16px 0;
}

ion-skeleton-text.list-date {
  width: 130px;
}
ion-skeleton-text.list-icon {
  width: 20px;
}
ion-skeleton-text.list-title {
  width: 247px;
}
ion-skeleton-text.list-comment {
  width: 30px;
}
ion-skeleton-text.list-stock {
  width: 140px;
}

.item-container {
  display: flex;
  gap: 16px;
}

.jatak-item {
  border: 1px solid #C5C5C7;
  border-radius: 8px;
  padding: 16px 20px;
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  ion-skeleton-text.list-title {
    width: 237px;
  }
}
@media screen and (max-width: 500px) {
  .item-container {
    display: block;
  }
  ion-skeleton-text.list-title {
    width: 230px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/item-list-skeleton/item-list-skeleton.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,oBAAA;AACF;;AAOA;EACE,6BAAA;EACA,cAAA;AAJF;;AAMA;EACE,cAAA;AAHF;;AAME;EACE,YAAA;AAHJ;AAKE;EACE,WAAA;AAHJ;AAKE;EACE,YAAA;AAHJ;AAKE;EACE,WAAA;AAHJ;AAKE;EACE,YAAA;AAHJ;;AAMA;EACE,aAAA;EACA,SAAA;AAHF;;AAKA;EACE,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AAFF;;AAIA;EAEI;IACE,YAAA;EAFJ;AACF;AAKA;EACC;IACG,cAAA;EAHF;EAME;IACE,YAAA;EAJJ;AACF","sourcesContent":["ion-thumbnail {\n  width: 40px;\n  height: 40px;\n  --border-radius: 8px;\n\n  //margin: 16px 16px 18px 16px;\n}\n//.img-wrap{\n// border-bottom: 1px solid #C5C5C7 ;\n//  margin-bottom:16px;\n//}\n.divider{\n  border-top:1px solid #C5C5C7;\n  margin:16px 0;\n}\n.list-text-container{\n  margin : 16px 0;\n}\nion-skeleton-text {\n  &.list-date {\n    width: 130px;\n  }\n  &.list-icon{\n    width: 20px;\n  }\n  &.list-title {\n    width: 247px;\n  }\n  &.list-comment {\n    width: 30px;\n  }\n  &.list-stock {\n    width: 140px;\n  }\n}\n.item-container{\n  display: flex;\n  gap:16px;\n}\n.jatak-item{\n  border:1px solid #C5C5C7;\n  border-radius: 8px;\n  padding: 16px 20px;\n  margin-top:10px;\n}\n@media screen and (max-width: 800px) {\n  ion-skeleton-text {\n    &.list-title {\n      width: 237px;\n    }\n  }\n}\n@media screen and (max-width: 500px) {\n .item-container{\n    display: block;\n  }\n  ion-skeleton-text {\n    &.list-title {\n      width: 230px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
