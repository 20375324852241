import {Injectable} from '@angular/core';
import {ApiService, TokenService} from '../../../core/services';
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})

export class WheelsServices {
  constructor(
    private _apiService: ApiService
  ) {
  }


  createWheel(params: any) {
    const url = '/spinning-wheels'
    return this._apiService.post(url, params)
  }

  updateWheel(id: any, params: any) {
    const url = `/spinning-wheels/${id}`
    return this._apiService.put(url, params)

  }

  updateSettings(id: any, params: any) {
    const url = `/spinning-wheels/${id}/settings`
    return this._apiService.put(url, params)
  }

  getWheelById(id: any) {
    const url = `/spinning-wheels/${id}`
    return this._apiService.get(url)
  }

  deleteWheelById(id: any) {
    const url = `/spinning-wheels/${id}`
    return this._apiService.del(url)
  }

  saveAsTemplate(params: any) {
    const url = '/spinning-wheels/save-as-template'
    return this._apiService.post(url, params)
  }

  getSpinningWheeList(tab: any, page: number) {
    let url: string = `/spinning-wheels?status=${tab}&page=${page}`

    return this._apiService.get(url)
  }

  getWheelOverviewDetail(overviewId: number) {
    let url = `/spinning-wheels/${overviewId}/overview`;
    return this._apiService.get(url)
  }

  getWheelTemplateById(id: any) {
    let url = `/templates/${id}`
    return this._apiService.get(url)
  }

  spinWheel(id: any) {
    let url = `/spins/${id}/spin`
    return this._apiService.post(url, {})
  }

}
