import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-accordion-skeleton',
  templateUrl: './accordion-skeleton.component.html',
  styleUrls: ['./accordion-skeleton.component.scss'],
})
export class AccordionSkeletonComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
