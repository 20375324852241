import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  OnChanges, SimpleChanges
} from '@angular/core';
import {ActionSheetController, AlertController, Platform} from '@ionic/angular';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {TranslateService} from "@ngx-translate/core";
import {take} from 'rxjs/operators';
import {ErrorHandlerService, ImageService} from 'app/core/services';
import {LangService} from "app/core/services";
import Swiper from "swiper";
import {SwiperComponent} from "swiper/angular";

@Component({
  selector: 'app-image-handler',
  templateUrl: './image-handler.component.html',
  styleUrls: ['./image-handler.component.scss'],
})
export class ImageHandlerComponent implements OnInit, AfterViewInit, OnChanges {
  // @ViewChild('fileinput') fileInput: ElementRef;
  @Input() disabled: boolean = false
  @Input() hasImage: boolean;
  @Input() editImage: boolean = true;
  @Input() isArchived: boolean = true;
  @Input() usingTemplate: boolean = true;
  @Input() challengeStarted: boolean = false
  @Input() multipleImage: boolean = false
  @Input() imageUrl;
  @Input() isGif: boolean = false;
  @Input() isCategoryChange: boolean = false
  @Input() fromStore: boolean = false
  @Output() imageUploadedUrl: EventEmitter<[]> = new EventEmitter<[]>();
  @Input() isRedmad: boolean = false
  @Input() isCompetition: boolean = false
  @ViewChild('swiper', {static: false}) swiper?: SwiperComponent;
  isProcessing = false;
  imageUploaded = false;
  imageLoaded = false;
  imageChangedEvent;
  @Input() aspectRatio = 1;
  editorOptions;
  showEditor = false;
  imgUrl: string;
  currentLanguage: string;
  imgSliderArray: any = []
  isPrevButtonHidden = true;
  isNextButtonHidden = true;
  isSlideAvailable: boolean
  activeSliderImageIndex: any;
  showUploadOptions: boolean = false
  @Input() isAuction: boolean = false;

  constructor(private actionSheetCtrl: ActionSheetController,
              private imageService: ImageService,
              private errorHandlerService: ErrorHandlerService,
              private platform: Platform,
              private androidPermissions: AndroidPermissions,
              private alertCtrl: AlertController,
              private _translate: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isCategoryChange']) {
    }

    if (changes['imageUrl']) {
      this.imageUrl = changes['imageUrl'].currentValue
      if (this.multipleImage && (this.isRedmad || this.fromStore)) {
        this.imgSliderArray = []
        this._setupForMultipleImage()
      }
    }
    if (changes['isAuction']) {
      this.isAuction = changes['isAuction'].currentValue;
    }

  }


  async ngOnInit() {
    if (this.usingTemplate) {
      this.editImage = true
      this.isArchived = false
    }
    if (this.multipleImage && !this.isRedmad) {
      this._setupForMultipleImage()
    }
    const currentLanguage = await LangService.getLang();
    this.currentLanguage = currentLanguage;
  }


  private _setupForMultipleImage() {
    for (const img of this.imageUrl) {
      this.imgSliderArray.push(img);
    }

  }

  ngAfterViewInit() {
    new Swiper(".swiper-container", {
      slidesPerView: 1,
      keyboard: false,
      mousewheel: false,
      navigation: {
        nextEl: ".swiper-button-next1",
        prevEl: ".swiper-button-prev1"
      },
      pagination: false,
      loop: false,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
    })
  }

  onswiperStart(event) {
    this.activeSliderImageIndex = event.realIndex
    this.updateArrowVisibility()
  }

  onSlideChange(event) {
    this.activeSliderImageIndex = event.realIndex
  }


  updateArrowVisibility() {
    this.isPrevButtonHidden = this.swiper.swiperRef.isBeginning;
    this.isNextButtonHidden = this.swiper.swiperRef.isEnd;
  }


  changeLoop(type: string) {
    if (this.hasImage) {
      const swiper = this.swiper.swiperRef
      if (type === "create") {
        swiper.loopCreate();
        swiper.slideTo(this.imgSliderArray.length + 1);
      }
      if (type === "destroy") {
        swiper.loopDestroy();
      }
      this.updateArrowVisibility()
    }
  }

  onSlideNext() {
    const swiper = this.swiper.swiperRef
    swiper.slideNext(500)
    this.updateArrowVisibility()
  }

  onSlidePrevious() {
    const swiper = this.swiper.swiperRef
    swiper.slidePrev(500)
    this.updateArrowVisibility()
  }

  async onImageDelete() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      message: this._translate.instant('_confirm_image_delete_'),
      buttons: [
        {
          text: this._translate.instant('_back_'),
          role: 'cancel',
        },
        {
          text: 'Ok',
          handler: () => this.imageRemoveLogic()
        },
      ],
    });
    await alert.present();
  }


  private uploadGIF(file: any, fileFormat: any) {
    this.clearAllImages()
    this.upload(file, fileFormat)
  }

  imageRemoveLogic() {
    this.imgSliderArray = [...this.imgSliderArray];
    this.imgSliderArray.splice(this.activeSliderImageIndex, 1)

    if (this.imgSliderArray.length === 0) {
      this.hasImage = false
      this.imageUploaded = false
    }
    this.detectSliderChanges()
  }

  clearAllImages() {
    this.imgSliderArray = []
    if (this.imgSliderArray.length === 0) {
      this.hasImage = false
      this.imageUploaded = false
    }
    this.detectSliderChanges()
  }

  detectSliderChanges() {
    const swiper = this.swiper.swiperRef
    this.isPrevButtonHidden = swiper.isBeginning;
    this.isNextButtonHidden = swiper.isEnd;
    swiper.loopDestroy();
    swiper.loopCreate();
    this.activeSliderImageIndex = swiper.realIndex
  }

  chooseFiles() {
    // this.fileInput.nativeElement.click();
  }

  async addImage(useCamera = false, alert) {
    const image = await this.imageService.addNewToGallery(useCamera);
    this.imageChangedEvent = await (await fetch(image.dataUrl)).blob();
    this.showEditor = true;
    alert.dismiss();
  }

  async onUpload() {
    const actionSheet = await this.actionSheetCtrl.create({
      cssClass: 'actions',
      buttons: [
        {
          text: this._translate.instant('_choose_files_'),
          icon: 'folder-outline',
          handler: () => {
            this.addImage(false, actionSheet);
          }
        },
        {
          text: this._translate.instant('_take_photo_'),
          icon: 'camera-outline',
          handler: () => {
            this.addImage(true, actionSheet);
          }
        }, {
          text: this._translate.instant('_cancel_'),
          icon: 'close-outline',
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
  }

  fileChangeEvent(event) {
    const file = event.target.files[0];
    event = null;
    this.imageChangedEvent = file;
    const fileFormat = file.type.split('/')[1];
    if (file.type === "image/gif") {
      this.handleGIFUpload(file, fileFormat)
      return
    }
    // if (this.multipleImage && this.hasImage && this.imgSliderArray[0].includes('.gif')) {
    //   this.imageRemoveLogic()
    // }
    this.gifMode(false);
    this.showEditor = true;
  }

  gifMode(status: boolean) {
    this.isGif = status;
  }


  private handleGIFUpload(file: any, fileFormat: any) {
    if (!this.multipleImage) {
      this.upload(file, fileFormat);
      this.gifMode(true)
      return;
    }

    if (this.multipleImage && this.hasImage && !this.isGif) {
      this.handleGIFForMultipleImage(file, fileFormat).then()
      this.gifMode(true)
      return;
    }

    if (this.multipleImage && this.hasImage && this.isGif) {
      this.clearAllImages()
      this.upload(file, fileFormat)
      this.gifMode(true)
      return;
    }
    this.upload(file, fileFormat)
    this.gifMode(true)
  }

  private async handleGIFForMultipleImage(file: any, fileFormat: any) {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      message: this._translate.instant('_gif_upload_message_'),
      buttons: [
        {
          text: this._translate.instant('_back_'),
          role: 'cancel',
        },
        {
          text: 'Ok',
          handler: () => this.uploadGIF(file, fileFormat)
        },
      ],
    });
    await alert.present();
  }

  onImageLoaded() {
    this.imageLoaded = true;
  }

  imageProcessed(event: any) {
    this.showEditor = false;
    const fileFormat = event.type.split('/')[1];
    this.showUploadOptions = false
    this.upload(event, fileFormat);
    this.gifMode(false)
  }

  closeEditor() {
    this.showEditor = false;
  }

  toggleOptions() {
    this.showUploadOptions = !this.showUploadOptions
  }

  editorLoaded(): void {
  }


  private upload(fileData: any, format: any) {
    if (!this.isProcessing) {
      this.isProcessing = true;
      this.imageLoaded = false;
      this.imageService.imageUpload(fileData, format, this.isCompetition || this.isAuction).pipe(
        take(1)
      ).subscribe(
        (imgUrl: any) => {
          if (this.multipleImage) {
            if (this.imgSliderArray.length === 5) {
              this.editupload(imgUrl)
              return;
            } else {
              this.uploadHandler(imgUrl)
            }

          }
          if (!this.multipleImage) {
            this.imgUrl = imgUrl;
            this.imageUrl = imgUrl
            this.hasImage = true
            this.imageUploaded = true;
            this.isProcessing = false;
            this.imageUploadedUrl.emit(imgUrl);
          }
        },
        err => this.handleError(err)
      );
    }
  }


  private editupload(imgUrl) {
    this.imgSliderArray = [...this.imgSliderArray]
    this.imgUrl = imgUrl;
    this.imageUploaded = true;
    this.hasImage = true
    this.imgSliderArray.splice(this.activeSliderImageIndex, 1)
    this.imgSliderArray.splice(this.activeSliderImageIndex, 0, imgUrl)
    setTimeout(() => {
      this.changeLoop('create')
    }, 1000)
    this.isProcessing = false;
    this.imageUploadedUrl.emit(this.imgSliderArray);
  }

  private uploadHandler(imgUrl) {
    this.imgSliderArray = [...this.imgSliderArray];
    this.imgUrl = imgUrl.url;
    this.imageUploaded = true;
    this.hasImage = true
    this.imgSliderArray.push(imgUrl.url)
    setTimeout(() => {
      this.changeLoop('create')
    }, 1000)
    this.isProcessing = false;
    this.imageUploadedUrl.emit(imgUrl);
  }

  //
  // private uploadAuctionHandler(imgUrl) {
  //   this.imgSliderArray = [...this.imgSliderArray];
  //   this.imgUrl = imgUrl.url;
  //   this.imageUploaded = true;
  //   this.hasImage = true
  //   this.imgSliderArray.push(imgUrl.url)
  //   setTimeout(() => {
  //     this.changeLoop('create')
  //   }, 1000)
  //   this.isProcessing = false;
  //   this.imageUploadedUrl.emit(imgUrl);
  // }
  //
  // private handleNewPostHandler(imgUrl) {
  //   this.imgSliderArray = [...this.imgSliderArray];
  //   this.imgUrl = imgUrl.url;
  //   this.imageUploaded = true;
  //   this.hasImage = true
  //   this.imgSliderArray.push(imgUrl.url)
  //   setTimeout(() => {
  //     this.changeLoop('create')
  //   }, 1000)
  //   this.isProcessing = false;
  //   this.imageUploadedUrl.emit(imgUrl);
  //
  // }


  private handleError(error): void {
    this.errorHandlerService.handleError(error || {code: -400}, 'image');
    this.isProcessing = false;
  }

}
