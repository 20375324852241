import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EditorOptions} from 'app/shared/interface';
import {
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_decorate,
  plugin_decorate_locale_en_gb,
  markup_editor_defaults,
  plugin_sticker,
  plugin_sticker_locale_en_gb,
  markup_editor_locale_en_gb,
  createMarkupEditorShapeStyleControls,
  createMarkupEditorToolbar,
  createMarkupEditorToolStyles,
  createDefaultImageOrienter
} from '@pqina/pintura';
import {da_locale, DEFAULT_DOKA_OPTIONS} from 'app/shared/data';
import {TranslateService} from "@ngx-translate/core";
import {an} from "@fullcalendar/core/internal-common";

const STICKER_URL = 'assets/vendor/doka-image-editor/stickers/';
const PRESET_URL = 'assets/vendor/doka-image-editor/presets/';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent implements OnInit {
  @Output() imageProcessed: EventEmitter<string> = new EventEmitter<string>();
  @Output() imageLoaded: EventEmitter<null> = new EventEmitter();
  @Output() closeEditor: EventEmitter<null> = new EventEmitter();

  @Input() mediaFile: any;
  @Input() aspectRatio: number;
  @Input() customOptions: EditorOptions = {};
  @Input() currentLanguage: string;

  dokaOptions: EditorOptions = DEFAULT_DOKA_OPTIONS;
  isVisible = true;
  redFrame = `${STICKER_URL}800x800-red.png`;
  whiteFrame = `${STICKER_URL}800x800-white.png`;
  rectangleRedFrame = `${STICKER_URL}1200x600-red.png`;
  rectangleWhiteFrame = `${STICKER_URL}1200x600-white.png`;
  emoji = `${STICKER_URL}loveFace.png`;
  googlesEmoji = `${STICKER_URL}goggles.png`;
  musclesEmoji = `${STICKER_URL}muscle-emoji.png`;
  omgEmoji = `${STICKER_URL}OMG-Emoji.png`;
  okEmoji = `${STICKER_URL}ok-hand-emoji.png`;
  partyEmoji = `${STICKER_URL}party-emoji.png`;
  partyPopperEmoji = `${STICKER_URL}party-popper-emoji.png`;
  laughingEmoji = `${STICKER_URL}laughing-emoji.png`;
  starEyesEmoji = `${STICKER_URL}star-eyes-emoji.png`;
  winkEmoji = `${STICKER_URL}wink-emoji.png`;
  droolingEmoji = `${STICKER_URL}drooling-emoji.png`;
  kissEmoji = `${STICKER_URL}kiss-emoji.png`;
  fireEmoji = `${STICKER_URL}fire-emoji.png`;
  shopCartEmoji = `${STICKER_URL}shopping-trolley-emoji.png`;
  burgerEmoji = `${STICKER_URL}burger-emoji.png`;
  friesEmoji = `${STICKER_URL}fries-emoji.png`;
  pizzaEmoji = `${STICKER_URL}pizza-emoji.png`;
  breadEmoji = `${STICKER_URL}bread-emoji.png`;
  cakeSliceEmoji = `${STICKER_URL}cake-slice-emoji.png`;
  plateEmoji = `${STICKER_URL}plate-emoji.png`;
  steakEmoji = `${STICKER_URL}steak-emoji.png`;
  poultryLegEmoji = `${STICKER_URL}poultry-leg-emoji.png`;

  fullRedSquarePreset = `${PRESET_URL}full_red_square.svg`;
  yellowCirclePreset = `${PRESET_URL}yellow_circle.svg`;
  yellowStarPreset = `${PRESET_URL}yellow_star.svg`;
  doubleRedCircle = `${PRESET_URL}double_red_circle.svg`;
  doubleSquare = `${PRESET_URL}double_square.svg`;
  fullRedCicle = `${PRESET_URL}full_red_circle.svg`;

  src = 'assets/images/camera@3x.jpg';

  // editor state
  locale = {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_decorate_locale_en_gb,
    ...plugin_sticker_locale_en_gb,
    ...markup_editor_locale_en_gb
  };
  options: any = {
    imageOrienter: createDefaultImageOrienter(),
    imageReader: createDefaultImageReader({
      orientImage: true
    }),
    ...markup_editor_defaults,
    ...this.closeEditorSettings()
  };
  cropSelectPresetOptions: any = [
    [
      'Crop',
      [
        [1, 'Square'],
        [[800, 400], 'Rectangle'],
      ],
    ],
  ];

  constructor(private _translate: TranslateService) {
  }


  ngOnInit() {
    this.initDoka();
    this.src = this.mediaFile;
    this.options = {
      ...this.options,
      ...this.localSettings(),
      ...this.imageWriterSettings(this.aspectRatio),
      ...this.decorateSettings(),
      ...this.cropSettings(),
      ...this.stickersSettings(),
      ...this.markupSettings(),
    };
  }


  danishLocal() {
    if (this.currentLanguage === 'da') {
      return da_locale;
    }
  }

  handleModalProcess($event) {
    this.emitImageProcessedEvent($event.dest);
  }

  handleModalLoad() {
    this.imageLoaded.emit();
  }

  onCloseEditor(): void {
    this.closeEditor.emit();
  }

  private emitImageProcessedEvent(image: string): void {
    this.imageProcessed.emit(image);
  }

  private initDoka(): void {
    this.dokaOptions = {...DEFAULT_DOKA_OPTIONS, ...this.customOptions};
    setPlugins(...this.getPlugins());
  }

  private getImageOutputConfig(aspectRatio: number): any {
    if (aspectRatio === 2) {
      return;
    }
    return this.makeImageOutputObject(800, 800);
  }

  private getFrames(aspectRatio: number): Array<any> {
    if (aspectRatio === 2) {
      return [this.makeFrameObject(this.rectangleRedFrame), this.makeFrameObject(this.rectangleWhiteFrame)];
    }
    return [this.makeFrameObject(this.redFrame), this.makeFrameObject(this.whiteFrame)];
  }

  private makeFrameObject(source: string) {
    return {
      src: source,
      width: '100%',
      height: '100%'
    };
  }

  private makeImageOutputObject(height: number, width: number) {
    return {
      width,
      height,
      upscale: true,
      fit: 'force'
    };
  }

  private imageWriterSettings(aspectRatio: number) {
    return {
      imageWriter: createDefaultImageWriter({
        targetSize: this.getImageOutputConfig(aspectRatio)
      })
    };
  }

  private localSettings() {
    return {
      locale: {
        ...this.locale,
        ...this.danishLocal(),
      }
    };
  }

  private stickersSettings() {
    if (!this.dokaOptions.sticker) {
      return {};
    }
    return {
      stickers: this.getStickers()
    };
  }

  private closeEditorSettings() {
    return {
      enableButtonClose: true
    };
  }

  private decorateSettings() {
    if (!this.dokaOptions.decorate) {
      return {};
    }
    return {
      decorateActiveTool: this.getActiveTool(),
      decoratePresets: [
        this.fullRedSquarePreset,
        this.yellowCirclePreset,
        this.yellowStarPreset,
        this.doubleRedCircle,
        this.doubleSquare,
        this.fullRedCicle
      ]
    };
  }

  private cropSettings() {
    return {
      imageCropAspectRatio: this.aspectRatio === 3 ? '' : this.aspectRatio === 1 ? 1 : 2,
      cropEnableSelectPreset: this.aspectRatio === 2
    };
  }

  private markupSettings() {
    return {
      markupEditorToolbar: createMarkupEditorToolbar(this.getMarkupEditorToolbar()),
      markupEditorToolStyles: createMarkupEditorToolStyles(),
      markupEditorShapeStyleControls: createMarkupEditorShapeStyleControls({
        fontFamilyOptions: [
          ['COOP', 'COOP']
        ],
      })
    };
  }

  private getPlugins(): Array<any> {
    const pluginArr = [plugin_crop];
    if (this.dokaOptions.decorate) {
      pluginArr.push(plugin_decorate);
    }
    if (this.dokaOptions.sticker) {
      pluginArr.push(plugin_sticker);
    }
    return pluginArr;
  }

  private getActiveTool(): string {
    return this.dokaOptions.text ? 'text' : this.dokaOptions.rectangle ? 'rectangle' : this.dokaOptions.priceTag ? 'preset' : '';
  }

  private getMarkupEditorToolbar(): Array<string> {
    const toolbarArr = [];
    if (this.dokaOptions.text) {
      toolbarArr.push('text');
    }
    if (this.dokaOptions.rectangle) {
      toolbarArr.push('rectangle');
    }
    if (this.dokaOptions.priceTag) {
      toolbarArr.push('preset');
    }
    return toolbarArr;
  }

  private getStickers(): Array<any> {
    const stickerArr = [];
    if (this.dokaOptions.frame) {
      stickerArr.push([
        this._translate.instant('_frame_'),
        this.getFrames(this.aspectRatio)
      ]);
    }
    if (this.dokaOptions.emoji) {
      stickerArr.push([
        'Emoji',
        this.getStickersEmoji()
      ]);
    }
    return stickerArr;

  }

  private getStickersEmoji(): Array<string> {
    return [
      this.emoji,
      this.googlesEmoji,
      this.omgEmoji,
      this.partyEmoji,
      this.partyPopperEmoji,
      this.laughingEmoji,
      this.starEyesEmoji,
      this.winkEmoji,
      this.droolingEmoji,
      this.kissEmoji,
      this.okEmoji,
      this.musclesEmoji,
      this.fireEmoji,
      this.shopCartEmoji,
      this.burgerEmoji,
      this.friesEmoji,
      this.pizzaEmoji,
      this.breadEmoji,
      this.cakeSliceEmoji,
      this.plateEmoji,
      this.steakEmoji,
      this.poultryLegEmoji
    ];
  }

}
