import {Component, Input, OnInit} from '@angular/core';
import {b} from "@fullcalendar/core/internal-common";
import {Router} from "@angular/router";
import {PopoverController} from "@ionic/angular";
import {FacebookService} from "../../../core/services";

@Component({
  selector: 'app-options-popover',
  templateUrl: './options-popover.component.html',
  styleUrls: ['./options-popover.component.scss'],
})
export class OptionsPopoverComponent implements OnInit {
  @Input() isSocialMedia: boolean = false
  @Input() isBookletOverview: boolean = false
  @Input() showJatak: boolean;
  @Input() showBooklet: boolean;
  @Input() showSms: boolean;
  @Input() showRegularPost: boolean
  @Input() showRedmad: boolean = false
  @Input() showAuction: boolean = false
  @Input() showCompetition: boolean = false
  @Input() showSpinning : boolean = false

  constructor(
    private _router: Router,
    private popoverCtrl: PopoverController,
    private _fbService : FacebookService
  ) {
  }

  ngOnInit() {
  }

 async onCreate(type: string) {
    if(this.isSocialMedia) {
      if (!(await this._fbService.isConnectedToFb())) {
        return;
      }
    }
    this.dismissPopover().then()
    if (type === 'jatak') {
      this._router.navigate(['/tabs/posts/jatak'], {
        queryParams: {origin: 'dashboard', type: 'jatak'},
        replaceUrl: true
      }).then()
    } else if (type === 'sms') {
      this._router.navigate(['/tabs/sms/create'], {queryParams: {origin: 'dashboard'}, replaceUrl: true}).then()
    } else if (type === 'regular') {
      this._router.navigate(['/tabs/posts/regular/create'], {
        queryParams: {origin: 'dashboard', type: 'regular'},
        replaceUrl: true
      }).then()
    } else if (type === 'challenge') {
      this._router.navigate(['/tabs/jatak/jatak-plus'], {queryParams: {origin: 'dashboard'}, replaceUrl: true}).then()
    } else if (type === 'redmad') {
      this._router.navigate(['/tabs/redmad/create'], {queryParams: {origin: 'dashboard'}}).then()
    } else if(type === 'competition' ) {
      this._router.navigate(['/tabs/posts/competition/create'], {
        queryParams: {origin: 'dashboard', type: 'competition'},
        replaceUrl: true
      }).then()
    } else if(type === 'auction') {
      this._router.navigate(['/tabs/posts/auction/create'], {
        queryParams: {origin: 'dashboard', type: 'auction'},
        replaceUrl: true
      }).then()
    } else if (type === 'wheel') {
      this._router.navigate(['/tabs/posts/wheel/create'], {
        queryParams: {origin: 'dashboard', type: 'wheel'}, replaceUrl: true
      }).then()
    }
    else {
      this._router.navigate(['/tabs/booklet/create'], {queryParams: {origin: 'dashboard'}, replaceUrl: true}).then()
    }
  }

  private async dismissPopover() {
    await this.popoverCtrl.dismiss();
  }
}


