import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngxs/store";
import {TabsAction} from "../../../store/actions/tabs.action";
import {FormAction} from "../../../store/actions/form.action";
import {GlobalService} from "../../../core/services/global.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {
  // @Input() pageTitle = 'No page title';
  @Input() isBackgroundWhite: boolean = false
  @Input() pageTitle = 'Settings';
  @Input() showBackArrow: boolean = false
  @Input() setting: boolean = false
  @Input() previousUrl = '/tabs/dashboard'
  @Input() isCampaign: boolean = false
  @Input() isTemplateFolder: boolean = false
  @Input() type: string = ''
  @Input() origin: string = ''

  constructor(
    private _location: Location,
    private _router: Router,
    private store: Store,
    private globalService: GlobalService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['previousUrl']) {
      this.previousUrl = changes['previousUrl'].currentValue
    }
    if(changes['origin']) {
      this.origin = changes['origin'].currentValue
    }
    if(changes['type']) {
      this.type = changes['type'].currentValue
    }
  }

  ngOnInit() {
  }

  onBack() {
    this.globalService.setData('customData', {checkConfirmation: 'true'});
    this.store.dispatch(new FormAction.ClearFormValue())
    if (this.isCampaign) {
      this.store.dispatch(new TabsAction.ClearTabState());
    }
    if (this.isTemplateFolder) {
      this._location.back()
    } else if (this.origin === 'campaign') {
      this._router.navigate([this.previousUrl], {replaceUrl: true, queryParams: {type: this.type}}).then()
    } else {
      this._router.navigate([this.previousUrl], {replaceUrl: true}).then()
    }
  }


}
